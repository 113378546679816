import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 242.000000 152.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,152.000000) scale(0.100000,-0.100000)">

<path d="M0 760 l0 -760 1210 0 1210 0 0 760 0 760 -1210 0 -1210 0 0 -760z
m1534 539 c81 -31 126 -85 126 -151 0 -47 -12 -63 -24 -33 -6 17 -36 21 -36 5
0 -5 -12 -10 -26 -10 -17 0 -28 6 -31 20 -3 11 -13 20 -22 20 -9 0 -34 3 -55
7 -34 5 -38 4 -33 -10 5 -13 1 -17 -18 -17 -16 0 -25 6 -25 15 0 8 -5 15 -11
15 -5 0 -8 -4 -5 -9 7 -11 -17 -21 -51 -21 -18 0 -24 4 -19 15 4 12 -3 15 -29
15 -28 0 -35 -4 -35 -19 0 -15 -10 -20 -53 -25 -45 -6 -55 -11 -64 -34 -6 -15
-18 -44 -27 -64 -15 -35 -15 -38 2 -47 11 -6 34 -5 65 2 61 14 119 -2 135 -38
7 -14 12 -51 12 -82 0 -70 -24 -112 -85 -147 -48 -29 -66 -32 -63 -13 2 8 -10
11 -44 9 -42 -3 -48 -1 -48 15 0 12 20 32 52 53 52 35 97 85 86 96 -4 3 -23
-6 -44 -20 -20 -14 -41 -23 -45 -20 -4 2 -28 -15 -53 -38 -51 -49 -91 -127
-108 -214 -10 -54 -38 -101 -52 -87 -3 3 -8 -2 -12 -11 -3 -9 -16 -16 -29 -16
-13 0 -26 -7 -29 -16 -10 -25 -34 1 -42 44 -8 41 10 174 32 235 41 114 52 317
18 317 -8 0 -14 -6 -14 -14 0 -14 -21 -26 -44 -26 -8 0 -26 18 -41 40 -22 32
-26 47 -22 76 18 110 166 191 198 108 11 -30 3 -32 154 26 207 79 341 94 459
49z m-567 -945 c12 -48 9 -89 -8 -100 -13 -7 -49 14 -49 30 0 7 -9 21 -20 31
-11 10 -20 25 -20 34 0 17 59 63 74 58 6 -2 16 -26 23 -53z"/>
<path d="M810 1160 c-8 -15 -8 -20 3 -20 14 0 37 19 37 32 0 16 -30 7 -40 -12z"/>
<path d="M959 1083 c-11 -3 -19 -20 -24 -50 -4 -25 -9 -53 -13 -62 -5 -14 -4
-14 11 -2 9 7 17 18 17 23 0 5 9 28 20 50 26 54 27 51 -11 41z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
